


.light {
    --nav-bg: #4CAF50;
    --button-hover: #4CAF50;
    --button-active-bg: #4CAF50;
    --button-bg: #3b82f6;
    --button-text: #000;
    --section-border: #ddd;
    --tab-notation-color: #ff6347;
    --general-text: #123123;
    --section-display: #176B87;
    --section-intro-outro: #c2d3f6;
    --section-solo: #EEF5FF;
    --section-verse: #cadcfb;
    --section-chorus: #b0cdf8;
    --annotation-text: #074387;
    --faint-text: #7b8a9e;
}

.dark {
    --nav-bg: #333;
    --button-hover: #1e1d1d;
    --button-active-bg: #297d2a;
    --button-bg: #333;
    --button-text: #fbd4d4;
    --section-border: #555;
    --tab-notation-color: #f3bbb3;
    --general-text: #22294b;
    --section-display: #6B728E;
    --section-intro-outro: #50577A;
    --section-solo: #474E68;
    --section-verse: #404258;
    --section-chorus: #33384d;
    --annotation-text: #89a7e1;
    --faint-text: #6379bc;
}