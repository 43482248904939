/* styles/App.css */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  background-image: url('./images/header.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: var(--general-text);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}


h1, h2 {
  color: var(--general-text);
}
