/* styles/Dashboard.css */

.dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 50px;
}

.dashboard-box {
    background-color: var(--section-solo);
    padding: 20px;
    margin: 15px;
    border-radius: 8px;
}

.profile-container {
    display: flex;
    flex-direction: row;
}

.profile-box {
    padding: 5px;
    align-items: center;
    align-content: center;
    text-align: center;
    margin: 5px;
    display: flex;
    flex-direction: column;

}

.dashboard h1 {
    margin-bottom: 20px;
    color: #1f2937;
}

.options-button, .overlay-button{
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    width: 150px;
    height: 200px;
}

.img-everything {
    background-image: url("../assets/style-everything.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.img-playsheet {
    background-image: url("../assets/style-playsheet.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.img-lyrics {
    background-image: url("../assets/style-lyrics.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.img-plain {
    background-image: url("../assets/style-plain.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}