/* styles/LoginPage.css */

.login-page {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    padding: 30px;
    text-align: center;
}

.login-page h2 {
    margin-bottom: 1rem;
}

.form-group {
    margin-bottom: 15px;
    text-align: left;
}

input[type="text"],
input[type="password"] {
    width: 100%;
    padding: 10px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.flavor-graphic {
    background-image: url('../images/logofull.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 200px;
    margin-bottom: 20px;
    background-color: #e0e7ff;
    padding: 15px;
    border-radius: 8px;
    font-weight: bold;
    font-size: clamp(1rem, 5vw, 3rem);
    align-content: center;
    overflow: hidden;
}
