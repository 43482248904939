button {
    margin: 5px;
    width: 100%;
    padding: 10px;
    background-color: var(--button-bg);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    color: var(--button-text);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
}

.short {
    position: relative;
    top: 10px;
    left: 10px;
    width: auto;
    font-size: 14px;
    font-weight: normal;
    padding: 10px;
    margin: 0 0 0px 0;
}

button:hover {
    background-color: var(--button-hover);
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.button-active {
    background-color: var(--button-active-bg);
    cursor: pointer;
    transition: background-color 0.3s ease;
}
